//Navs
.nav {
    .nav-link:not(.disabled) {
        color: $body-color;
        &.active,&:hover{
            color: $component-active-bg;
        }
    }
    &.nav-pills{
        .nav-link{
            &.active{
                color: $white;
            }
            &.disabled{
                color: $text-muted;
            }
        }
    }
}

.nav {
    &.flex-column {
        .nav-link {
            padding-left: 0;
        }
    }
}

.nav.nav-filter {
    .nav-link {
        position: relative;
        padding: 0;
        color: $body-color;

        &.active,
        &:hover {
            color: $text-muted;
        }

        &.active {
            text-decoration: line-through;
        }
    }

    &:not(.flex-column) {
        .nav-item:not(:last-child) {
            margin-right: $spacer;
        }
    }
}

.nav-overflow {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1px; // to prevent active links border bottom from hiding
    // Hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
  }


.text-white,
.text-light {
    .nav {
        .nav-link {
            color: rgba($white, .75);
            &.active,
            &:hover {
                color: $component-active-color;
            }
        }

        &.nav-filter {
            .nav-link.active {
                text-decoration: line-through;
            }
        }
    }
}



//Nav vertical outline
.nav-tabs-vertical{
    flex-direction: column;
    flex-shrink: 0;
    flex: 1;
}
.nav.nav-pills.nav-pills-white{
    .nav-link.active{
        background-color: $white;
        color: $component-active-bg;
    }
}