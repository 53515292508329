//Underline tabs
.nav-tabs{
    border-bottom: 1px solid $border-color;
    .nav-link{
        padding-left: 0px;
        padding-right: 0px;
        &:not(:first-child){
            margin-left: 1.5rem;
        }
    }
    .nav-link{
        margin-bottom: -1px;
        border-bottom: 1px solid transparent;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        background-color: transparent;
        
        &.active{
            background-color: transparent;
            border-bottom-color: $component-active-bg;
        }
    }
}